<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">
        {{ $l.get("customReport-create.header") }}
      </h3>
      <CustomReportForm :customReport="customReport" :onSubmit="onSubmit" />
    </div>
  </div>
</template>

<script>
import InitCustomReportCreateInteractor from "@/business/custom-report-create/init-custom-report-create";
import SubmitFormInteractor from "@/business/custom-report-create/submit-form";
import CustomReportCreateScreenController from "@/adapters/controllers/screen-custom-report-create";
import CustomReportForm from "@/application/components/custom-report/CustomReportForm";

import Loader from "@/application/components/Loader";

export default {
  screen_name: "customReport-create",
  components: { CustomReportForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        initCustomReportCreate: null,
        submitForm: null
      },
      isLoading: false,
      data: null,
      customReport: {
        name: ""
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(CustomReportCreateScreenController);

    //{ INTERACTORS
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    this.interactors.initCustomReportCreate = this.$injector.get(
      InitCustomReportCreateInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initCustomReportCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      // console.log(this.customReport);
      this.interactors.submitForm.handle(this.customReport);
    }
  }
};
</script>

<style lang="scss"></style>
